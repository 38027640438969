<template>
    <div class="page-wrapper">

        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <div class=" pull-left">
                                <div class="page-title">Emploi du temps </div>
                            </div>
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboard'">Tableau
                                        de
                                        Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Emploi Du Temps</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-16 col-sm-12">
                            <div class="card">

                                <div class="card-head d-flex align-items-center justify-content-between">
                                    <header>Emploi du temps </header>
                                    <!--  <div class="d-flex align-items-center">
                                        <span class="me-3">Année:</span>
                                        <select class="form-select" id="academicYearSelect"
                                            v-model="form.annee_academique" @change="validatedata('annee_academique')">
                                            <option value="">Toutes les années académiques</option>
                                            <option v-for="annee_academique in annee_academiques"
                                                :value="annee_academique.id" :key="annee_academique.id">{{
                    annee_academique.intitule }}</option>
                                        </select>
                                    </div> -->
                                    <div class="d-flex align-items-center">
                                        <span class="me-3">Classe:</span>
                                        <select class="form-select" id="classSelect" v-model="form.id_classe"
                                            @change="validatedata('classe')">
                                            <option value="">Toutes les classes</option>
                                            <option v-for="classe in classes" :value="classe.id" :key="classe.id">
                                                {{ classe.type_formation.intitule }}{{
                    classe.nom_classe }} {{ classe.niveau }} {{ classe.type_classe }}
                                            </option>
                                        </select>
                                    </div>
                                    <!--  <div class="d-flex align-items-center">"
                                        <span class="me-3">Semestre:</span>
                                        <select class="form-select" id="classSelect" v-model="form.id_semestre"
                                            @change="validatedata('semestre')">
                                            <option value="">Toutes les semestres</option>"
                                            <option v-for="semestre in semestres" :value="semestre.id"
                                                :key="semestre.id">
                                                {{
                    semestre.intitule }}
                                            </option>
                                        </select>
                                    </div> -->
                                </div>
                                <!-- Main calendar display with hours column -->
                                <div class="card-body">
                                    <div class="panel-body">
                                        <div class="has-toolbar">
                                            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
                                            <!--  ref="fullCalendar" :options="calendarOptions"    -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <appafterContent />
        </div>
        <appfooter />

    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-hidden="true" :form="form">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addEventTitle">Ajouter Cours</h5>
                    <h5 class="modal-title" id="editEventTitle">Modifier Cours</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="">
                        <input type="hidden" id="id" name="id">
                        <div class="row">
                            <div class="col-md-12 mb-4">
                                <label for="cheese">Cours</label>
                                <select class="form-select" id="categorySelect" readonly tabIndex="-1"
                                    v-model="form.id_cours" @change="validatedata('id_cours')">
                                    <option value="">Selectionner le Cours</option>
                                    <option v-for="(cour, index) in cours" :value="cour.id" :key="index">
                                        {{ cour.matiere.intitule }}
                                        {{ cour.formateur.user.nom }}
                                        {{ cour.classe.nom_classe }}
                                        <!--  {{ cour.salle.intitule }} -->
                                        {{ cour.semestre.intitule }}
                                    </option>
                                </select>
                                <span class="erreur">{{ this.id_cours_erreur }}</span>
                            </div>
                            <div class="col-md-12 mb-4">
                                <label for="cheese">Année Academique</label>
                                <select class="form-select" id="academicYearSelect" v-model="form.id_annee_academique"
                                    @change="validatedata('annee_academique')">
                                    <option value="">Toutes les années académiques</option>
                                    <option v-for="annee_academique in annee_academiques" :value="annee_academique.id"
                                        :key="annee_academique.id">{{ annee_academique.intitule }}</option>
                                </select>
                                <span class="erreur">{{ this.annee_academique_erreur }}</span>
                            </div>
                            <div class="col-md-12 mb-4">
                                <label for="cheese">Selectionner la salle</label>
                                <select class="form-select" id="academicYearSelect" v-model="form.id_salle"
                                    @change="validatedata('id_salle')">
                                    <option value="">Toutes les salles</option>
                                    <option v-for="salle in salles" :value="salle.id" :key="salle.id">{{ salle.intitule
                                        }}</option>
                                </select>
                                <span class="erreur">{{ this.id_salle_erreur }}</span>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label>Date Début</label>
                                    <input type="date" class="form-control datetimepicker" placeholder="Start Date"
                                        name="starts_at" id="starts-at" v-model="form.date_debut">
                                    <span class="erreur">{{ this.date_debut_erreur }}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Date Fin</label>
                                    <input type="date" class="form-control datetimepicker" placeholder="Start Date"
                                        name="starts_at" id="starts-at" v-model="form.date_fin">
                                    <span class="erreur">{{ this.date_fin_erreur }}</span>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label>Heure Début</label>
                                    <input class="form-control datetimepicker" type="time" id="starts-at"
                                        v-model="form.heure_debut" />
                                    <span class="erreur">{{ this.heure_debut_erreur }}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Heure Fin</label>
                                    <input class="form-control datetimepicker" type="time" id="starts-at"
                                        v-model="form.heure_fin" />
                                    <span class="erreur">{{this.heure_fin_erreur }}</span>
                                </div>
                            </div>



                        </div>
                        <div class="modal-footer bg-whitesmoke pr-0">
                            <button type="button" class="btn btn-round btn-primary" id="add-event"
                                @click.prevent="validerAvantAjout()">Ajouter</button>
                            <!--   <button type="button" class="btn btn-round btn-primary"
                                id="edit-event">Modifier</button> -->
                            <button type="button" id="close" class="btn btn-danger"
                                data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '../../eventBus';
import axios from '@/axios';
import Form from 'vform';
import Swal from 'sweetalert2';
import flatPickr from 'vue-flatpickr-component';
//import 'flatpickr/dist/flatpickr.css';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
//import calen from "../../../public/assets/js/pages/calendar/calendar.min.js"

import FullCalendar from '@fullcalendar/vue3';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';




export default {
    props:{
        form:{
            type: Object,
            default: ()=>{}
        }
    },
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        flatPickr,
        FullCalendar,
    },
    props: ['cour'],
    name: "EmploiDuTempsComponent",

    data() {
        return {

            /* calendarOptions:{
                plugins:[dayGrid, interactionPlugin, timeGrid],
                initialView: 'timeGridWeek',
                locale: 'fr',
                headerToolbar:{
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'dayGridMonth, timeGridWeek, timeGridDay',
                },
                height: "auto",
                allDaySlot: false,
                slotMinTime: '08:00:00',
                slotMaxTime: '18:00:00',
                dateClick: this.handleDateClick,

            }, */
            calendarOptions: {
    plugins: [dayGrid, interactionPlugin, timeGrid],
    initialView: 'timeGridWeek',
    locale: 'fr',
    headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    height: "auto",
    allDaySlot: false,
    slotMinTime: '08:00:00',
    slotMaxTime: '21:00:00',
    slotLabelInterval: '01:00',
    className: "fc-event-success",
    dateClick: this.handleDateClick,
    events: this.fetchEvents,
    eventContent: function(info) {
        let professeur = info.event.extendedProps.professeur || 'Pas de professeur';
        let salle = info.event.extendedProps.salle || 'Pas de salle';
        let classe = info.event.extendedProps.classe || 'Pas de classe';
        return {
            html: `<div>
                     <strong>${info.event.title} ${classe}</strong><br>
                     <span>${professeur}</span><br>
                     <span>${salle}</span>
                   </div>`
        };
    },
  
    eventClick: (info) => {
          this.listeView = true;
          //console.log(this.listeView);
          // Swal.fire({
          //     title: info.event.title,
          //     text: info.event.extendedProps.professeur,
          //     icon: 'info',
          //     confirmButtonText: 'Fermer'
          // });
        }
    },

            form: new Form({
                'id_cours': "",
                'id_salle': "",
                'heure_debut': "",
                'heure_fin': "",
                'date_debut': "",
                'date_fin': "",
                'id_classe': "",
                'id_semestre': "",
            
                'annee_academique': "",
                'id_annee_academique': "",

            }),

            id_cours_erreur: "",
            heure_debut_erreur: "",
            heure_fin_erreur: "",
            date_debut_erreur: "",
            date_fin_erreur: "",
            annee_academique_erreur: "",
            id_salle_erreur: "",

            /* id_classe_erreur: "",
            id_formateur_erreur: "",
            id_matiere_erreur: "",
            id_salle_erreur: "",
            id_semestre_erreur: "", */

            etatForm: false,
            editModal: false,
            cours: [],
            classes: [],
            formateurs: [],
            matieres: [],
            salles: [],
            semestres: [],
            annee_academiques: [],
            idCour: "",
            calendar: null,
            events: [],
            showModal: false,

        }
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },

    watch: {
        'form.annee_academique': 'filterEvents',
        'form.id_classe': 'filterEvents',
        'form.id_semestre': 'filterEvents'
    },

    beforeMount(){
        /* this.$data.calendarOptions.events= {
            url: 'http://localhost:8000/api/get/emploi',
            method: 'GET',
            failure: error =>{
                console.log('erreur est: ', error.message);
            }
        } */
        this.filterEvents();
    },

    mounted() {
this.filterEvents();
        this.get_classe();
        this.get_cours();
        this.get_formateur();
        this.get_salle();
        this.get_semestre();
        this.get_matiere();
        this.get_annee_academique();

        bus.on('courModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.cour);
        });
        //this.fetchEvents();
    },

    methods: {
        ...mapActions(["login", "logout"]),

       /*  async filterEvents() {

            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            
            const params = {
                annee_academique: this.form.annee_academique,
                id_classe: this.form.id_classe,
                id_semestre: this.form.id_semestre
            };

            // Mettez à jour l'URL des événements avec les paramètres de filtre
            this.$data.calendarOptions.events = {
                url: 'http://localhost:8000/api/get/emploi',
                method: 'GET',
                extraParams: params,
                failure: error => {
                    console.log('erreur est: ', error.message);
                }
            };

            // Rechargez les événements du calendrier
            this.$refs.fullCalendar.getApi().refetchEvents();
        }, */
        async filterEvents() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const params = {
                annee_academique: this.form.annee_academique,
                id_classe: this.form.id_classe,
                id_semestre: this.form.id_semestre
            };

            this.$data.calendarOptions.events = {
                url: 'http://localhost:8000/api/get/emploi',
                method: 'GET',
                extraParams: params,
                failure: error => {
                    console.log('erreur est: ', error.message);
                }
            };

            setTimeout(() => {
                if (this.$refs.fullCalendar && this.$refs.fullCalendar.getApi()) {
                    this.$refs.fullCalendar.getApi().refetchEvents();
                } else {
                    console.error('Calendar is not initialized');
                }
            }, 500);
        },

        async soumettre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            /*  formdata.append('intitule', this.form.intitule); */
            formdata.append('heure_debut', this.form.heure_debut);
            formdata.append('heure_fin', this.form.heure_fin);
            formdata.append('date_debut', this.form.date_debut);
            formdata.append('date_fin', this.form.date_fin);
            formdata.append('id_cour', this.form.id_cours);
            formdata.append('id_salle', this.form.id_salle);
            formdata.append('id_annee_academique', this.form.id_annee_academique);
         /*    formdata.append('id_matiere', this.form.id_matiere);
            formdata.append('id_salle', this.form.id_salle);
            formdata.append('id_semestre', this.form.id_semestre); */
            try {
                const create_store = await axios.post('/emploidutemps/store', formdata, { headers });
                this.closeModal();
                showDialog6("Emploi du temps ajouté avec succès");
                bus.emit('courAjoutee;')
                this.resetForm();
                bus.emit('courAjoutee');
                setTimeout(() => {
                    window.location.href = '/emploi-du-temps';
                }, 1500);

            }
            catch (e) {
                /* console.log(e.request.status) */
                if (e.request.status === 404) {
                    this.closeModal()
                    showDialog3("Ce cours existe déjà");
                }
                else {
                    this.closeModal();
                    showDialog3("Une erreur est survenue lors de l\'enregistrement");
                }
            }
        },

        handleDateClick(clickInfo){
            //this.$emit('exampleModal1', clickInfo)
            //this.showModal= true;
            $('#exampleModal1').modal('show');
            this.setModalOpen(clickInfo);
        },
        closeModal(){
            //this.$emit('exampleModal1', clickInfo)
            //this.showModal= true;
            $('#exampleModal1').modal('hide');
           // this.setModalOpen(clickInfo);
        },

        setModalOpen(obj){
            let dateAndTime= obj.dateStr.split("T");
            this.form.date_debut=dateAndTime[0];
            this.form.date_fin=dateAndTime[0];
            this.form.heure_debut=dateAndTime[1].substr(0, 8);
            this.form.heure_fin=dateAndTime[1].substr(0, 8);
            //console.log(this.form.hour)
        },

        formatDateTime(dateTime) {
            // Utilisez une fonction pour formater la date
            return this.formatDate(new Date(dateTime));
        },
        formatDate(date) {
            const day = date.getDate();
            const monthNumber = date.getMonth() + 1;
            const year = date.getFullYear();

            // Tableau des trois premières lettres des mois en français
            const monthAbbreviations = [
                "Jan", "Fév", "Mar", "Avr", "Mai", "Juin",
                "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"
            ];

            // Obtenez les trois premières lettres du mois correspondant au numéro du mois
            const month = monthAbbreviations[monthNumber - 1];

            return `${day} ${month} ${year}`;

        },

        verifCaratere(nom) {
            const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
            return valeur.test(nom);
        },


        validatedata(champ) {
            var i = 0;
            switch (champ) {
                case 'id_cours':
                    this.id_cours_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.id_cours === "") {
                        this.id_cours_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'id_salle':
                    this.id_salle_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.id_salle === "") {
                        this.id_salle_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'heure_debut':
                    this.heure_debut_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.heure_debut === "") {
                        this.heure_debut_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'heure_fin':
                    this.heure_fin_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.heure_fin === "") {
                        this.heure_fin_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'date_debut':
                    this.date_debut_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.date_debut === "") {
                        this.date_debut_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'date_fin':
                    this.date_fin_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.date_fin === "") {
                        this.date_fin_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }


                    break;
                case 'annee_academique':
                    this.annee_academique_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.id_annee_academique === "") {
                        this.annee_academique_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }
                    break;

                default:
                    break;
            }
        },


        validatedataOld() {
           

            this.id_cours_erreur = "";
            this.id_salle_erreur = "";
            this.heure_debut_erreur = "";
            this.heure_fin_erreur = "";
            this.date_debut_erreur = "";
            this.date_fin_erreur = "";
            this.annee_academique_erreur = "";
     
            var i = 0;

            if (this.form.id_cours === "") {
                this.id_cours_erreur = "Ce champ est obligatoire"
                i = 1;
            } 
            if (this.form.id_salle === "") {
                this.id_salle_erreur = "Ce champ est obligatoire"
                i = 1;
            } 
            if (this.form.id_annee_academique === "") {
                this.annee_academique_erreur = "Ce champ est obligatoire"
                i = 1;
            } 
     
            if (this.form.heure_debut === "") {
                this.heure_debut_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.heure_fin === "") {
                this.heure_fin_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.date_debut === "") {
                this.date_debut_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.date_fin === "") {
                this.date_fin_erreur = "Ce champ est obligatoire"
                i = 1;
            }

            if (i == 1) return true;

            return false;
        },

        validerAvantAjout() {
            const isNomDirectionValid = this.validatedataOld();

            console.log(isNomDirectionValid);


            if (isNomDirectionValid === true) {
                this.etatForm = false;
                this.editModal = false;
                return 0;
            } else {

                if (this.editModal === true) {
                    this.etatForm = false;
                    /* this.form.intitule = this.form.intitule.toUpperCase(); */
                    this.update_cour(this.idCour);

                    this.editModal = false;
                }
                else {
                    this.etatForm = true;
                    /*  this.form.intitule = this.form.intitule.toUpperCase(); */
                    this.soumettre();

                    this.editModal = false;
                }
            }

        },

        resetForm() {
           // this.intitule_erreur = "";

            this.id_cours_erreur = "";
            this.id_salle_erreur = "";
            this.heure_debut_erreur = "";
            this.heure_fin_erreur = "";
            this.date_debut_erreur = "";
            this.date_fin_erreur = "";
            this.annee_academique_erreur = "";

            this.form.id_cours = "";
            this.form.id_salle = "";
            this.editModal = false;
            this.form.heure_debut = "";
            this.form.heure_fin = "";
            this.form.date_debut = "";
            this.form.date_fin = "";
            this.form.annee_academique = "";

          

            const eventData = {
                editModal: false,
            };
            bus.emit('courDejaModifier', eventData);

        },

        async get_classe() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/classe/all', { headers })
                .then(response => {
                    this.classes = response.data.classe;
                    console.log("this.classes")
                    console.log(this.classes)

                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recupération des derniers classes', 'error')
                });
        },
        async get_annee_academique() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/annee_academique/index", { headers })
                .then((response) => {
                    this.annee_academiques = response.data.annee_academique;
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recuperation des année_academiques",
                        "error"
                    );
                });
        },
        async get_formateur() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/formateur/index', { headers })
                .then(response => {
                    this.formateurs = response.data.formateur;

                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recupération des derniers formatyeurs', 'error')
                });
        },

        get_matiere() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            axios.get('/matiere/index', { headers })
                .then(response => {
                    this.matieres = response.data.matiere;
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernieres matieres', 'error')
                });
        },
        get_semestre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            axios.get('/semestre/index', { headers })
                .then(response => {
                    this.semestres = response.data.semestre;
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernieres matieres', 'error')
                });
        },

        async get_cours() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/cour/all', { headers })
                .then(response => {
                    this.cours = response.data.cour
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des derniers cours', 'error')
                });
        },
        async get_salle() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/salle/index', { headers })
                .then(response => {
                    this.salles = response.data.salle
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernières salles', 'error')
                });
        },

        async update_cour(id) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
           /*  formdata.append('intitule', this.form.intitule); */
            formdata.append('heure_debut', this.form.heure_debut);
            formdata.append('heure_fin', this.form.heure_fin);
            formdata.append('date_cour', this.form.date_cour);
            formdata.append('id_classe', this.form.id_classe);
            formdata.append('id_formateur', this.form.id_formateur);
            formdata.append('id_matiere', this.form.id_matiere);
           /*  formdata.append('id_salle', this.form.id_salle); */
            formdata.append('id_semestre', this.form.id_semestre);
            //if(this.form.nom!==""){
            try {
                await axios.post('/cour/update/' + id, formdata, { headers });
                showDialog6("Cours modifié avec succès");
                bus.emit('courAjoutee');
                const eventData = {
                    editModal: false,
                };
                bus.emit('courDejaModifier', eventData);
            }
            catch (e) {
                /* console.log(e.request.status) */
                if (e.request.status === 404) {
                    Swal.fire('Erreur !', 'Ce Cours existe déjà', 'error')
                }
                else {
                    Swal.fire('Erreur !', 'Une erreur est survenue lors de l\'enregistrement', 'error')
                }
            }
        },
        monterToupdate(direction) {
            this.idDirection = direction.id;
            this.editModal = direction.editModal;
            this.form.nom_direction = direction.direction;
            this.form.id_user = direction.id_user;
        },

       /*  fetchEvents() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
      axios.get('emploidutemps/all', { headers })
        .then(response => {
          this.events = response.data; // Assuming your API returns an array of event objects
          this.initCalendar();
        })
        .catch(error => {
          console.error("There was an error fetching the events!", error);
        });
    }, */
    

    }
}
</script>
<style>
.event-content {
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.fc-timegrid-event {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fc-daygrid-event {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>