// src/mixins/permissionMixin.js
import axios from "@/axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      permissions: [],
    };
  },


  methods: {
    async get_permission() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };


      try {
        const response = await axios.get(`/get/all/permission/${this.userIdrole}`, { headers });
        this.permissions = response.data.permission;
        console.log("this.permissions");       
        console.log(this.permissions);       
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des permissions', 'error');
      }
    },
    
    hasReadPermission(fonction) {

     
      return this.permissions.some(permission => permission.read ==1 && permission.fonctionnalite.intitule === fonction);
    },

    hasCreatePermission(fonction) {
      return this.permissions.some(permission => permission.create ==1 && permission.fonctionnalite.intitule === fonction);
    },
    hasUpdatePermission(fonction) {
      return this.permissions.some(permission => permission.update ==1 && permission.fonctionnalite.intitule === fonction);
    },
    hasDeletePermission(fonction) {
      return this.permissions.some(permission => permission.delete ==1 && permission.fonctionnalite.intitule === fonction);
    },
  },
};