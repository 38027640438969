<template>
    <div class="page-wrapper">
        <!-- start header -->
        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <div class=" pull-left">
                                <div class="page-title">Dashboard</div>
                            </div>
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                        href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                    <!-- start widget -->
                    <div class="row ">
                        <div class="col-xl-3 col-lg-6">
                            <div class="card comp-card">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <div class="col mt-0">
                                                <h4 class="info-box-title">Nombre d'Étudiants Inscrits</h4>
                                            </div>
                                            <h3 class="mt-1 mb-3 info-box-title col-green">4,586</h3>
                                            <div class="progress">
                                                <div class="progress-bar l-bg-purple" style="width: 45%"></div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div id="sparkline7"><canvas
                                                    style="display: inline-block; width: 267px; height: 70px; vertical-align: top;"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="card comp-card">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <div class="col mt-0">
                                                <h4 class="info-box-title">New Students</h4>
                                            </div>
                                            <h3 class="mt-1 mb-3 info-box-title col-green">589</h3>
                                            <div class="progress">
                                                <div class="progress-bar l-bg-red" style="width: 45%"></div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div id="sparkline12"><canvas
                                                    style="display: inline-block; width: 367px; height: 70px; vertical-align: top;"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="card comp-card">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <div class="col mt-0">
                                                <h4 class="info-box-title">Taux de Présence Global </h4>
                                            </div>
                                            <h3 class="mt-1 mb-3 info-box-title col-green">48</h3>
                                            <div class="progress">
                                                <div class="progress-bar l-bg-green" style="width: 45%"></div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div id="sparkline9"><canvas
                                                    style="display: inline-block; width: 167px; height: 70px; vertical-align: top;"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="card comp-card">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <div class="col mt-0">
                                                <h4 class="info-box-title">Nombre de Cours Actuels </h4>
                                            </div>
                                            <h3 class="mt-1 mb-3 info-box-title col-green">2,479</h3>
                                            <div class="progress">
                                                <div class="progress-bar l-bg-orange" style="width: 45%"></div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div id="sparkline16" class="text-center"><canvas
                                                    style="display: inline-block; width: 215px; height: 70px; vertical-align: top;"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end widget -->
                    <!-- chart start -->
                    <div class="row">
                        <div class="col-12 col-sm-12 col-lg-6">
                            <div class="card">
                                <div class="card-head">
                                    <header>Progrès des Cours</header>
                                </div>
                                <div class="card-body">
                                    <div id="chart2"></div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="text-muted font-15 text-truncate">Target</p>
                                            <h5>
                                                <i class="fa fa-arrow-circle-up col-green ms-1 me-1"></i>$15.3k
                                            </h5>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-muted font-15 text-truncate">Last
                                                week</p>
                                            <h5>
                                                <i class="fa fa-arrow-circle-down col-red ms-1 me-1"></i>$2.8k
                                            </h5>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-muted text-truncate">Last
                                                Month</p>
                                            <h5>
                                                <i class="fa fa-arrow-circle-up col-green ms-1 me-1"></i>$12.5k
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-6">
                            <div class="card">
                                <div class="card-head">
                                    <header>Statistiques de Cours</header>
                                </div>
                                <div class="card-body">
                                    <div id="schart3"></div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="text-muted font-15 text-truncate">Target</p>
                                            <h5>
                                                <i class="fa fa-arrow-circle-up col-green ms-1 me-1"></i>$15.3k
                                            </h5>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-muted font-15 text-truncate">Last
                                                week</p>
                                            <h5>
                                                <i class="fa fa-arrow-circle-down col-red ms-1 me-1"></i>$2.8k
                                            </h5>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-muted text-truncate">Last
                                                Month</p>
                                            <h5>
                                                <i class="fa fa-arrow-circle-up col-green ms-1 me-1"></i>$12.5k
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div class="card card-box">
                                <div class="card-head">
                                    <header>Devoir/examen a faire</header>
                                    <div class="tools">
                                        <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                        <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                        <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                                    </div>
                                </div>
                                <div class="card-body ">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table display product-overview mb-30" id="support_table">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Task</th>
                                                        <th>Assigned Professors</th>
                                                        <th>status</th>
                                                        <th>Progress</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Preparation for cricket team</td>
                                                        <td>Kenny Josh</td>
                                                        <td>
                                                            <span class="label label-sm label-success">Done</span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-success progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="90"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:100%;"> <span class="sr-only">100%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Annual function preparation</td>
                                                        <td> Mark</td>
                                                        <td>
                                                            <span class="label label-sm label-warning"> Pending </span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-warning progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="90"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:70%;"> <span class="sr-only">70%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Final year exam paper set</td>
                                                        <td>Felix </td>
                                                        <td>
                                                            <span class="label label-sm label-danger">Suspended</span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-danger progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="90"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:50%;"> <span class="sr-only">50%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Placement report</td>
                                                        <td>Beryl</td>
                                                        <td>
                                                            <span class="label label-sm label-success ">Done</span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-success progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="100"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:100%;"> <span class="sr-only">100%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Fees collection report</td>
                                                        <td>Jayesh</td>
                                                        <td>
                                                            <span class="label label-sm label-success ">Done</span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-success progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="90"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:100%;"> <span class="sr-only">100%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Library book status</td>
                                                        <td>Sharma</td>
                                                        <td>
                                                            <span class="label label-sm label-danger">Suspended</span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-danger progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="90"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:20%;"> <span class="sr-only">20%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Exam Paper set</td>
                                                        <td>John Deo</td>
                                                        <td>
                                                            <span class="label label-sm label-warning"> Pending </span>
                                                        </td>
                                                        <td>
                                                            <div class="progress">
                                                                <div class="progress-bar progress-bar-warning progress-bar-striped active"
                                                                    role="progressbar" aria-valuenow="90"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style="width:80%;"> <span class="sr-only">80%
                                                                        Complete</span> </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="card  card-box">
                                <div class="card-head">
                                    <header>Notifications</header>
                                    <div class="tools">
                                        <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                        <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                        <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                                    </div>
                                </div>
                                <div class="card-body no-padding height-9">
                                    <div class="row">
                                        <div class="noti-information notification-menu">
                                            <div class="notification-list mail-list not-list small-slimscroll-style">
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-primary"> <i class="fa fa-user-o"></i>
                                                    </span> <span class="text-purple">Abhay Jani</span> Added you as
                                                    friend
                                                    <span class="notificationtime">
                                                        <small>Just Now</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon blue-bgcolor"> <i class="fa fa-envelope-o"></i>
                                                    </span> <span class="text-purple">John Doe</span> send you a mail
                                                    <span class="notificationtime">
                                                        <small>Just Now</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-success"> <i class="fa fa-check-square-o"></i>
                                                    </span> Success Message
                                                    <span class="notificationtime">
                                                        <small> 2 Days Ago</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-warning"> <i class="fa fa-warning"></i>
                                                    </span> <strong>Database Overloaded Warning!</strong>
                                                    <span class="notificationtime">
                                                        <small>1 Week Ago</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-primary"> <i class="fa fa-user-o"></i>
                                                    </span> <span class="text-purple">Abhay Jani</span> Added you as
                                                    friend
                                                    <span class="notificationtime">
                                                        <small>Just Now</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon blue-bgcolor"> <i class="fa fa-envelope-o"></i>
                                                    </span> <span class="text-purple">John Doe</span> send you a mail
                                                    <span class="notificationtime">
                                                        <small>Just Now</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-success"> <i class="fa fa-check-square-o"></i>
                                                    </span> Success Message
                                                    <span class="notificationtime">
                                                        <small> 2 Days Ago</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-warning"> <i class="fa fa-warning"></i>
                                                    </span> <strong>Database Overloaded Warning!</strong>
                                                    <span class="notificationtime">
                                                        <small>1 Week Ago</small>
                                                    </span>
                                                </a>
                                                <a href="javascript:;" class="single-mail"> <span
                                                        class="icon bg-danger"> <i class="fa fa-times"></i>
                                                    </span> <strong>Server Error!</strong>
                                                    <span class="notificationtime">
                                                        <small>10 Days Ago</small>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="full-width text-center p-t-10">
                                                <button type="button"
                                                    class="btn purple btn-outline btn-circle margin-0">View All</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- start new student list -->
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="card  card-box">
                                <div class="card-head">
                                    <header>Liste des derniers inscrits</header>
                                    <div class="tools">
                                        <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                        <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                        <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                                    </div>
                                </div>
                                <div class="card-body ">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table display product-overview mb-30">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Name</th>
                                                        <th>Assigned Professor</th>
                                                        <th>Date Of Admit</th>
                                                        <th>Fees</th>
                                                        <th>Branch</th>
                                                        <th>Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Jens Brincker</td>
                                                        <td>Kenny Josh</td>
                                                        <td>27/05/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-success">paid</span>
                                                        </td>
                                                        <td>Mechanical</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Mark Hay</td>
                                                        <td> Mark</td>
                                                        <td>26/05/2017</td>
                                                        <td>
                                                            <span class="label label-sm label-warning">unpaid </span>
                                                        </td>
                                                        <td>Science</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Anthony Davie</td>
                                                        <td>Cinnabar</td>
                                                        <td>21/05/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-success ">paid</span>
                                                        </td>
                                                        <td>Commerce</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>David Perry</td>
                                                        <td>Felix </td>
                                                        <td>20/04/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-danger">unpaid</span>
                                                        </td>
                                                        <td>Mechanical</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Anthony Davie</td>
                                                        <td>Beryl</td>
                                                        <td>24/05/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-success ">paid</span>
                                                        </td>
                                                        <td>M.B.A.</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Alan Gilchrist</td>
                                                        <td>Joshep</td>
                                                        <td>22/05/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-warning ">unpaid</span>
                                                        </td>
                                                        <td>Science</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Mark Hay</td>
                                                        <td>Jayesh</td>
                                                        <td>18/06/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-success ">paid</span>
                                                        </td>
                                                        <td>Commerce</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Sue Woodger</td>
                                                        <td>Sharma</td>
                                                        <td>17/05/2016</td>
                                                        <td>
                                                            <span class="label label-sm label-danger">unpaid</span>
                                                        </td>
                                                        <td>Mechanical</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="tblEditBtn">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="tblDelBtn">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end new student list -->
                </div>
            </div>
            <appafterContent />
        </div>
        <appfooter />
    </div>
    <!-- end new student list -->
</template>
<script>
import alerte from "../alerte/listeAlerteComponent.vue";
import bus from "../../eventBus.js";
import axios from "@/axios";
import Form from "vform";

import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

import { mapState, mapActions } from "vuex";

export default {
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        alerte,
    },
    data() {
        return {
            form: new Form({
                nom: "",
                prenom: "",
                genre: "",
                adresse: "",
                telephone: "",
                email: "",
                date_naissance: "",
                lieu_naissance: "",
                nationalite: "",
                id_role: "",
                id_specialite: "",
                id_departement: "",
                id_service: "",
                type: "",
                situation_matrimoniale: "",
            }),
            utilisateurs: [],
            paiements: [],
            roles: [],
            audits: [],
            inscriptions: [],
            countDep: null,
            countElev: null,
            countDirection: null,
            countFiliere: null,
        };
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },
    mounted() {
        this.fetchUtilisateurs();
        this.get_audit();
        this.get_departement();
        this.get_eleve();
        this.get_direction();
        this.get_filiere();
        this.get_paiement();
        this.getInscription();

        // this.fetchRoles().then(() => {
        // ... (autres actions au montage)
        //});
        /*  this.userNom= this.$store.state.userNom;
          this.userPrenom= this.$store.state.userPrenom;
          this.userIdrole= this.$store.state.userIdrole;
          this.userPhoto= this.$store.state.userPhoto; */

        /*     console.log("this.$store.state.user");
        console.log(this.$store); */
    },

    methods: {
        ...mapActions(["login", "logout"]),
        async get_paiement() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/paiement/get_last", { headers })
                .then((response) => {
                    const allpaiement = response.data.paiement;

                    const formattedPaiement = allpaiement.map((paie) => {
                        return {
                            id: paie.id,
                            id_eleve: paie.eleve.user.id,
                            matricule: paie.eleve.user.matricule,
                            photo: paie.eleve.user.photo,
                            eleve_prenom: paie.eleve.user.prenom,
                            eleve_nom: paie.eleve.user.nom,
                            date_naissance: paie.eleve.user.date_naissance,
                            adresse: paie.eleve.user.adresse,
                            matricule: paie.eleve.user.matricule,

                            type_recouvrement: paie.type_recouvrement,
                            mode_paiement: paie.mode_paiement,
                            reference: paie.reference,
                            classe: paie.eleve.inscription
                                .map((p) => p.classe.nom_classe)
                                .join(", "),
                            niveau: paie.eleve.inscription
                                .map((p) => p.classe.niveau)
                                .join(", "),
                            type_classe: paie.eleve.inscription
                                .map((p) => p.classe.type_classe)
                                .join(", "),
                            type_formation: paie.eleve.inscription
                                .map((p) => p.classe.type_formation.intitule)
                                .join(", "),
                            annee: paie.concerner
                                .map((p) => p.annee_academique.intitule)
                                .join(", "),
                            mois: paie.concerner.map((p) => p.mois.intitule).join(", "),
                            id_annee: paie.concerner
                                .map((p) => p.annee_academique.id)
                                .join(", "),
                            id_mois: paie.concerner.map((p) => p.mois.id).join(", "),
                            montant: paie.montant,
                            editModal: true,
                        };
                    });
                    this.paiements = formattedPaiement;
                    // console.log(this.paiements);
                    /*  this.initDataTable(); */
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recuperation des paiements",
                        "error"
                    );
                });
        },
        formatDateTime(dateTime) {
            // Utilisez une fonction pour formater la date
            return this.formatDate(new Date(dateTime));
        },
        formatDate(date) {
            const day = date.getDate();
            const monthNumber = date.getMonth() + 1;
            const year = date.getFullYear();

            // Tableau des trois premières lettres des mois en français
            const monthAbbreviations = [
                "Jan",
                "Fév",
                "Mar",
                "Avr",
                "Mai",
                "Juin",
                "Juil",
                "Aoû",
                "Sep",
                "Oct",
                "Nov",
                "Déc",
            ];

            // Obtenez les trois premières lettres du mois correspondant au numéro du mois
            const month = monthAbbreviations[monthNumber - 1];

            return `${day} ${month} ${year}`;
        },
        formatTime(timerecup) {
            return this.getTime(new Date(timerecup));
        },

        getTime(date) {
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Ajoutez un zéro devant les heures ou les minutes si elles sont inférieures à 10
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

            return `${formattedHours}:${formattedMinutes}`;
        },

        getImageUrl(url) {
            //return url ? `${window.location.origin}/storage/${url}` : "";
            //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
            return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
        },
        async fetchUtilisateurs() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            // Exemple : remplacez cela par votre logique de récupération de données
            // et assurez-vous que les données sont correctement assignées à utilisateurs
            await axios
                .get("/user/index", { headers })
                .then((response) => {
                    this.utilisateurs = response.data.user.slice(0, 7);
                    // console.log(this.utilisateurs);
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors de la récupération des utilisateurs",
                        error
                    );
                });
        },
        async getInscription() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            // Exemple : remplacez cela par votre logique de récupération de données
            // et assurez-vous que les données sont correctement assignées à utilisateurs
            await axios
                .get("/inscription/last", { headers })
                .then((response) => {
                    const allInscriptions = response.data.inscription;

                    const inscriptionVal = allInscriptions.filter((inscription) => {
                        return inscription.statut == 1;
                    });

                    const formattedInscriptionVal = inscriptionVal.map((inscrit) => {
                        return {
                            id: inscrit.id,
                            photo: inscrit.eleve.user.photo,
                            matricule: inscrit.eleve.user.matricule,
                            nom: inscrit.eleve.user.nom,

                            prenom: inscrit.eleve.user.prenom,

                            classe:
                                inscrit.classe.type_formation.intitule +
                                " " +
                                inscrit.classe.nom_classe +
                                " " +
                                inscrit.classe.niveau +
                                " " +
                                inscrit.classe.type_classe,
                            annee_academique: inscrit.annee_academique.intitule,

                            statut: inscrit.statut,

                            date: inscrit.created_at,
                            montant: inscrit.montant,

                            nom_departement:
                                inscrit.classe.unite_de_formation.departement.nom_departement,
                            nom_filiere:
                                inscrit.classe.unite_de_formation.nom_unite_formation,
                        };
                    });
                    this.inscriptions = formattedInscriptionVal;
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors de la récupération des etudiants inscrit",
                        error
                    );
                });
        },
        async get_departement() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/departement/all", { headers })
                .then((response) => {
                    this.countDep = response.data.departement.length;
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recupération du nombre de département",
                        "error"
                    );
                });
        },
        async get_filiere() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/unite_de_formation/all", { headers })
                .then((response) => {
                    this.countFiliere = response.data.unite_de_formation.length;
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recupération du nombre de filiere",
                        "error"
                    );
                });
        },
        /*   async get_direction() {
            await axios
              .get("/formateur/index")
              .then((response) => {
                this.countDirection = response.data.formateur.length;
              })
              .catch((error) => {
                Swal.fire(
                  "Erreur!",
                  "Une erreur est survenue lors de la recupération des audit",
                  "error"
                );
              });
          }, */
        async get_direction() {
            // Récupérer le token depuis le local storage
            const token = localStorage.getItem("token");

            // Vérifier si le token existe
            if (this.token) {
                // Ajouter le token à l'en-tête d'autorisation
                const headers = { Authorization: `Bearer ${token}` };

                try {
                    // Envoyer la requête GET avec l'en-tête d'autorisation
                    const response = await axios.get("/direction/index", { headers });

                    // Traiter la réponse
                    this.countDirection = response.data.direction.length;
                } catch (error) {
                    // Gérer les erreurs
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la récupération des directions",
                        "error"
                    );
                }
            } else {
                // Si le token n'est pas disponible, rediriger l'utilisateur vers la page de connexion
                window.location.href = "/login";
            }
        },

        async get_eleve() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/user/count", { headers })
                .then((response) => {
                    this.countElev = response.data.eleve.length;
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recupération du nombre des étudiants",
                        "error"
                    );
                });
        },
        async get_audit() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/audit/all", { headers })
                .then((response) => {
                    this.audits = response.data.audit;
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recupération des audit",
                        "error"
                    );
                });
        },

        countRoleOccurrences() {
            const roleOccurrences = {};

            this.utilisateurs.forEach((user) => {
                const roleIntitule = user.role ? user.role.intitule : "Non défini";

                if (!roleOccurrences[roleIntitule]) {
                    roleOccurrences[roleIntitule] = 1;
                } else {
                    roleOccurrences[roleIntitule]++;
                }
            });

            return roleOccurrences;
        }, //audit/all
    },
};
</script>