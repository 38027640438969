<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Inscription Eleve</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboard'"
                    >Accueil</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-inscription-eleve'"
                    >Inscription</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Inscription Eleve</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab"
                      >Inscription Non Payée</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#tab2" class="nav-link" data-bs-toggle="tab"
                      >Inscription Payée</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Inscription Non Payée</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'nouvelle-inscription'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div
                                  class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length"
                                >
                                  <label class="me-2">Afficher</label>
                                  <select
                                    name="example47_length"
                                    aria-controls="example47"
                                    class="form-select-sm"
                                    @change="updatePerPage"
                                    v-model="limitQueryInval"
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="
                                      get_inscription_invalide(currentPageInval)
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th>Identifiant Kairos</th>
                                  <th>Photo</th>
                                  <th>Matricule</th>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  <th>Date et Lieu de Naissance</th>
                                  <th>Classe</th>
                                  <th>Année Académique</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(inscription, index) in inscriptionInv"
                                  :key="index"
                                >
                                  <td>{{ inscription.id_kairos }}</td>
                                  <td class="patient-img">
                                    <img
                                      :src="getImageUrl(inscription.photo)"
                                      alt="Etu"
                                    />
                                  </td>
                                  <td>{{ inscription.matricule }}</td>
                                  <td class="left">{{ inscription.nom }}</td>
                                  <td class="left">{{ inscription.prenom }}</td>
                                  <td class="left">
                                    {{ inscription.date }}
                                    {{ inscription.lieu_naissance_eleve }}
                                  </td>
                                  <td class="left">{{ inscription.classe }}</td>
                                  <td class="left">
                                    {{ inscription.annee_academique }}
                                  </td>

                                  <td>
                                    <a
                                      class="tblEditBtn"
                                      @click="openModal(inscription)"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a
                                      class="tblDelBtn"
                                      @click="deleteInscription(inscription)"
                                    >
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              class="row"
                              v-if="this.inscriptionInv.length > 0"
                            >
                              <div class="col-sm-12 col-md-5">
                                <div
                                  class="dataTables_info"
                                  id="datable_1_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Affichage de {{ from_beginInval }} à
                                  {{ to_beginInval }} sur
                                  {{ totalInval }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div
                                  class="dataTables_paginate paging_simple_numbers"
                                  id="datable_1_paginate"
                                >
                                  <paginate-component
                                    :total-pages="total_pageInval"
                                    :total="totalInval"
                                    :per-page="per_pageInval"
                                    :current-page="current_pageInval"
                                    @pagechanged="pagPageInval"
                                  ></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fontawesome-demo" id="tab2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Inscription payée</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div
                                  class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length"
                                >
                                  <label class="me-2">Afficher</label>
                                  <select
                                    name="example47_length"
                                    aria-controls="example47"
                                    class="form-select-sm"
                                    @change="updatePerPage"
                                    v-model="limitQueryVal"
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="
                                      get_inscription_valide(currentPageVal)
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="exemple1"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th>Identifiant Kairos</th>
                                  <th>Photo</th>
                                  <th>Matricule</th>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  <th>Date et Lieu de Naissance</th>
                                  <th>Classe</th>
                                  <th>Année Académique</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(inscription, index) in inscriptionVal"
                                >
                                  <td>{{ inscription.id_kairos }}</td>
                                  <td class="patient-img">
                                    <img
                                      :src="getImageUrl(inscription.photo)"
                                      alt="Etu"
                                    />
                                  </td>
                                  <td>{{ inscription.matricule }}</td>
                                  <td class="left">{{ inscription.nom }}</td>
                                  <td class="left">{{ inscription.prenom }}</td>
                                  <td class="left">
                                    {{ inscription.date }}
                                    {{ inscription.lieu_naissance_eleve }}
                                  </td>
                                  <td class="left">{{ inscription.classe }}</td>
                                  <td class="left">
                                    {{ inscription.annee_academique }}
                                  </td>

                                  <td>
                                    <a
                                      class="tblEditBtn"
                                      @click="openModal(inscription)"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      class="tblDelBtn"
                                      @click="deleteInscription(inscription)"
                                    >
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              class="row"
                              v-if="this.inscriptionVal.length > 0"
                            >
                              <div class="col-sm-12 col-md-5">
                                <div
                                  class="dataTables_info"
                                  id="datable_1_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Affichage de {{ from_beginVal }} à
                                  {{ to_beginVal }} sur {{ totalVal }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div
                                  class="dataTables_paginate paging_simple_numbers"
                                  id="datable_1_paginate"
                                >
                                  <paginate-component
                                    :total-pages="total_pageVal"
                                    :total="totalVal"
                                    :per-page="per_pageVal"
                                    :current-page="current_pageVal"
                                    @pagechanged="pagPageVal"
                                  ></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Inscription</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    href="'/dashboard'"
                    >Tableau de Bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" href="'/liste-inscription-eleve'"
                    >Inscrire eleve</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Inscription</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button
                    id="panel-button"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editInscription.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    FormulaireModification,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  name: "listeInscriptionCompenent",

  data() {
    return {
      form: new Form({
        intitule: "",
      }),
      inscriptions: [],
      editModal: false,
      idInscription: "",
      inscriptionVal: [],
      inscriptionInv: [],

      limitQueryVal: 5,
      total_pageVal: 0,
      totalVal: 0,
      per_pageVal: 0,
      current_pageVal: 1,
      from_beginVal: 0,
      to_beginVal: 0,
      is_loadingVal: false,

      currentPageVal: 1,
      lastPageVal: 1,

      limitQueryInval: 5,
      total_pageInval: 0,
      totalInval: 0,
      per_pageInval: 0,
      current_pageInval: 1,
      from_beginInval: 0,
      to_beginInval: 0,
      is_loadingInval: false,
      searchQuery: "",
      currentPageInval: 1,
      lastPageInval: 1,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  async created() {
    await this.get_permission();
    this.get_inscription_valide(this.currentPageVal);
    this.get_inscription_invalide(this.currentPageInval);

    bus.on("inscriptionAjoutee", () => {
      // Écouter l'événement de nouvelle utilisateur ajoutée
      this.get_inscription_valide(this.currentPageVal);
      this.get_inscription_invalide(this.currentPageInval);
    });

    bus.on("inscriptionDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_inscription_valide(this.currentPageVal);
      this.get_inscription_invalide(this.currentPageInval);
    });
  },

  /*  mounted() {
    this.get_inscription_valide(this.currentPageVal);
    this.get_inscription_invalide(this.currentPageInval);

    bus.on("inscriptionAjoutee", () => {
      // Écouter l'événement de nouvelle utilisateur ajoutée
      this.get_inscription_valide(this.currentPageVal);
    this.get_inscription_invalide(this.currentPageInval);
    });

    bus.on("inscriptionDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_inscription_valide(this.currentPageVal);
        this.get_inscription_invalide(this.currentPageInval);
    });
  }, */

  methods: {
    ...mapActions(["login", "logout"]),

    /*   async getInscription() {
      await Promise.all([
        this.get_inscription_valide(this.currentPageVal),
        this.get_inscription_invalide(this.currentPageInval),
      ]);
    }, */

    pagPageVal(pagination) {
      this.current_pageVal = pagination;
      this.get_inscription_valide(this.currentPageVal);
    },
    pagPageInval(pagination) {
      this.current_pageInval = pagination;
      this.get_inscription_invalide(this.currentPageInval);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQueryVal !== 5) {
        this.limitQueryVal = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_inscription_invalide(this.currentPageVal);
      }
      if (this.limitQueryInval !== 5) {
        this.limitQueryInval = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_inscription_valide(this.currentPageInval);
      }
    },

    async fetchInscrption(endpoint, page, filterCondition, perPage) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const response = await axios.get(endpoint, {
          params: {
            page: page,
            per_page: perPage,
          },
          headers: headers,
        });

        const inscription = response.data.inscription.data;
     
        const filteredInscrptions = inscription.filter((inscrit) => {
          return (
            filterCondition(inscrit) &&
            Object.values(inscrit)
              .reduce((acc, value) => {
                if (typeof value === "string") {
                  acc.push(value.toLowerCase());
                }
                return acc;
              }, [])
              .some((value) => value.includes(this.searchQuery.toLowerCase()))
          );
        });

        return {
          filteredInscrptions: filteredInscrptions.map((inscrit) => ({
            id: inscrit.id,
            id_kairos: inscrit.id_kairos ? inscrit.id_kairos : "Nulle",
            photo: inscrit.eleve.user.photo,
            matricule: inscrit.eleve.user.matricule,
            nom: inscrit.eleve.user.nom,
            genre_eleve: inscrit.eleve.user.genre,
            prenom: inscrit.eleve.user.prenom,
            adresse_eleve: inscrit.eleve.user.adresse,
            telephone_eleve: inscrit.eleve.user.telephone,
            mail_eleve: inscrit.eleve.user.email,
            date: inscrit.eleve.user.date_naissance,
            lieu_naissance_eleve: inscrit.eleve.user.lieu_naissance,
            id_eleve: inscrit.eleve.id,
            nationalite_eleve: inscrit.eleve.user.nationalite,
            classe:
              inscrit.classe.type_formation.intitule +
              " " +
              inscrit.classe.nom_classe +
              " " +
              inscrit.classe.niveau +
              " " +
              inscrit.classe.type_classe,
            annee_academique: inscrit.annee_academique.intitule,
            contact1: inscrit.eleve.contact_urgence1,
            contact2: inscrit.eleve.contact_urgence2,
            editModal: true,
            statut: inscrit.statut,
            id_tuteur: inscrit.eleve.tuteur.id,
            nom_tuteur: inscrit.eleve.tuteur.user.nom,
            genre_tuteur: inscrit.eleve.tuteur.user.genre,
            prenom_tuteur: inscrit.eleve.tuteur.user.prenom,
            adresse_tuteur: inscrit.eleve.tuteur.user.adresse,
            telephone_tuteur: inscrit.eleve.tuteur.user.telephone,
            mail_tuteur: inscrit.eleve.tuteur.user.email,
            date_tuteur: inscrit.eleve.tuteur.user.date_naissance,
            lieu_naissance_tuteur: inscrit.eleve.tuteur.user.lieu_naissance,
            nationalite_tuteur: inscrit.eleve.tuteur.user.nationalite,
            id_classe: inscrit.classe.id,
            id_departement: inscrit.classe.unite_de_formation.departement.id,
            id_filiere: inscrit.classe.unite_de_formation.id,
            id_annee_academique: inscrit.annee_academique.id,
            nom_departement:
              inscrit.classe.unite_de_formation.departement.nom_departement,
            nom_filiere: inscrit.classe.unite_de_formation.nom_unite_formation,
            annee_academique: inscrit.annee_academique.intitule,
          })),
          page: {
            // Autres données supplémentaires que vous souhaitez retourner
            total_page: response.data.inscription.last_page,
            total: response.data.inscription.total,
            per_page: response.data.inscription.per_page,
            current_page: response.data.inscription.current_page,
            from_begin: response.data.inscription.from,
            to_begin: response.data.inscription.to,
          },
        };
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Erreur!",
          "Une erreur est survenue lors de la récupération des inscrits",
          "error"
        );
        return [];
      }
    },

    async get_inscription_invalide(page) {
      this.currentPageInval = page;
      const filterCondition = (inscrit) => inscrit.statut == 0;
      const perPage = this.limitQueryInval;
      if (this.hasReadPermission("Inscription")) {
      
        const usersResponse = await this.fetchInscrption(
          "/inscription/invalide",
          this.current_pageInval,
          filterCondition,
          perPage
        );

        this.inscriptionInv = usersResponse.filteredInscrptions;

        this.total_pageInval = usersResponse.page.total_page;
        this.totalInval = usersResponse.page.total;
        this.per_pageInval = usersResponse.page.per_page;
        this.current_pageInval = usersResponse.page.current_page;
        this.from_beginInval = usersResponse.page.from_begin;
        this.to_beginInval = usersResponse.page.to_begin;
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_inscription_valide(page) {
      this.currentPageVal = page;
      const filterCondition = (inscrit) => inscrit.statut == 1;
      const perPage = this.limitQueryVal;
      if (this.hasReadPermission("Inscription")) {
        const usersResponse = await this.fetchInscrption(
          "/inscription/valide",
          this.current_pageVal,
          filterCondition,
          perPage
        );

        this.inscriptionVal = usersResponse.filteredInscrptions;

        this.total_pageVal = usersResponse.page.total_page;
        this.totalVal = usersResponse.page.total;
        this.per_pageVal = usersResponse.page.per_page;
        this.current_pageVal = usersResponse.page.current_page;
        this.from_beginVal = usersResponse.page.from_begin;
        this.to_beginVal = usersResponse.page.to_begin;
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },


    changement(event) {
      this.interesser = event;
    },

    resetForm() {
      this.form.input = "";
      this.form.intitule = "";
    },

    async deleteInscription(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Inscription")) {
        Swal.fire({
          title: "Êtes-vous sûr de vouloir supprimer cette inscription?",
          text: "Cette action sera irréversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (result.value) {
            axios
              .delete(`/inscription/delete/${type.id}`, { headers })
              .then((resp) => {
                showDialog6("Inscription supprimée avec succès");
                this.get_inscription();
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    openModal(inscription) {
      this.editModal = true;
      const eventData = {
        inscription: inscription,
      };
      bus.emit("inscriptionModifier", eventData);
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },
  },
};
</script>
